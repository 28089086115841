import { stringify } from 'qs';
import request from '@/utils/request';
import CONFIG from '@/utils/config';
// import log from '@/utils/log';

export async function queryByLinkfacebook(params) {
  // console.log(`${CONFIG.API_SERVER_DOWNLOAD}/facebook/getVideo?${stringify(params)}`);
  return request(`${CONFIG.API_SERVER_DOWNLOAD}/facebook/getVideo?${stringify(params)}`);
}
export async function queryByUsername(id) {
  // console.log(`${API_SERVER_2}/tiktok/getByUsername/${id}`);
  return request(`${CONFIG.API_SERVER_DOWNLOAD}/tiktok/getByUsername/${id}`);
}

export async function queryByLinktiktok(params) {
  // console.log(`${API_SERVER_2}/tiktok/getVideo?${stringify(params)}`);
  return request(`${CONFIG.API_SERVER_DOWNLOAD}/tiktok/getVideo?${stringify(params)}`);
}

export async function queryByLinkdouyin(params) {
  // console.log(`${API_SERVER_2}/douyin/getVideo?${stringify(params)}`);
  return request(`${CONFIG.API_SERVER_DOWNLOAD}/douyin/getVideo?${stringify(params)}`);
}
export async function queryByLinkyoutube(params) {
  //   console.log(`${API_SERVER_2}/tiktok/getById/${id}`);
  return request(`${CONFIG.API_SERVER_DOWNLOAD}/youtube/getVideo?${stringify(params)}`);
}
export async function queryByLinkprintest(params) {
  //   console.log(`${API_SERVER_2}/tiktok/getById/${id}`);
  return request(`${CONFIG.API_SERVER_DOWNLOAD}/printest/getVideo?${stringify(params)}`);
}
export async function queryByLinkinstagram(params) {
  //   console.log(`${API_SERVER_2}/tiktok/getById/${id}`);
  return request(`${CONFIG.API_SERVER_DOWNLOAD}/instagram/getVideo?${stringify(params)}`);
}
export async function queryByLinktwiter(params) {
  //   console.log(`${API_SERVER_2}/tiktok/getById/${id}`);
  return request(`${CONFIG.API_SERVER_DOWNLOAD}/twiter/getVideo?${stringify(params)}`);
}
