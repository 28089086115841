/* eslint-disable import/extensions */
/* eslint-disable camelcase */
/* import { routerRedux } from 'dva/router';
import { message } from 'antd'; */
// import log from '@/utils/log';
import {
  queryMenus,
  queryArticleList,
  queryArticleInfoByName,
  queryCategoryInfoByName,
  queryChildrenCategoryAll,
  queryDataSiteUrl,
} from '@/services/websRedux';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export default {
  namespace: 'webs',

  state: {
    dataMenu: {},
    dataCategoryInfo: {},
    dataDetailArticle: {},
    dataSite: {},
  },
  effects: {
    *fetchMenus({ payload, callback }, { call, put }) {
      const response = yield call(queryMenus, payload);
      // console.log('servie model fetch response: %o ', response);
      if (response) {
        yield put({
          type: 'saveMenu',
          payload: response || {},
        });
      }
      if (callback) callback(response);
    },
    *fetchListArticle({ payload, callback }, { call }) {
      const response = yield call(queryArticleList, payload);
      if (callback) callback(response);
    },
    *fetchArticleInfoByName(
      {
        payload: { name, filter, noRedux },
        callback,
      },
      { call, put }
    ) {
      const response = yield call(queryArticleInfoByName, name, filter);
      if (response && !noRedux) {
        yield put({ type: 'saveArticleInfo', payload: response || {} });
      }
      if (callback) callback(response);
    },
    *fetchCategoryInfoByName(
      {
        payload: { name, sitesId, languagesId },
        callback,
      },
      { call, put }
    ) {
      const response = yield call(queryCategoryInfoByName, name, sitesId, languagesId);
      if (response) {
        yield put({ type: 'saveCategoryInfo', payload: response || {} });
      }
      if (callback) callback(response);
    },

    *fetchAllChildrenCategory({ payload, callback }, { call, put }) {
      const response = yield call(queryChildrenCategoryAll, payload);
      if (callback) callback(response);
    },
    *fetchSiteUrl(
      {
        payload: { url },
        callback,
      },
      { call, put }
    ) {
      const response = yield call(queryDataSiteUrl, url);
      if (response) {
        yield put({ type: 'saveDataSiteUrl', payload: response || {} });
      }
      if (callback) callback(response);
    },
  },

  reducers: {
    saveMenu(state, action) {
      // console.log(action.payload);

      return { ...state, dataMenu: action.payload.result };
    },
    saveArticleInfo(state, action) {
      return {
        ...state,
        dataDetailArticle: action.payload && {
          ...action.payload,
          images: action.payload?.images?.map(item2 => ({
            ...item2,
            file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
              item2.file
            }`,
          })),
          categories: action.payload?.categories && {
            ...action.payload?.categories,
            image: action.payload?.categories?.image?.file && {
              ...action.payload?.categories.image,
              file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
                action.payload?.categories?.image?.file
              }`,
            },
            sites: action.payload?.categories?.sites && {
              ...action.payload?.categories?.sites,

              icon: action.payload?.categories?.sites?.icon?.file && {
                ...action.payload?.categories?.sites?.icon,
                file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
                  action.payload?.categories?.sites?.icon?.file
                }`,
              },
              logo: action.payload?.categories?.sites?.logo?.map(item2 => ({
                ...item2,
                file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
                  item2?.file
                }`,
              })),
            },
          },
        },
      };
    },
    saveCategoryInfo(state, action) {
      return {
        ...state,
        dataCategoryInfo: action.payload && {
          ...action.payload,
          image: action.payload?.image?.file && {
            ...action.payload.image,
            file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
              action.payload?.image?.file
            }`,
          },
          sites: action.payload?.sites && {
            ...action.payload?.sites,

            icon: action.payload?.sites?.icon?.file && {
              ...action.payload?.sites?.icon,
              file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
                action.payload?.sites?.icon?.file
              }`,
            },
            logo: action.payload?.sites?.logo?.map(item2 => ({
              ...item2,
              file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
                item2?.file
              }`,
            })),
          },
        },
      };
    },
    saveDataSiteUrl(state, action) {
      return {
        ...state,
        dataSite: action.payload && {
          ...action.payload,
          icon: action.payload?.icon?.file && {
            ...action.payload?.icon,
            file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
              action.payload?.icon?.file
            }`,
          },
          logo: action.payload?.logo?.map(item2 => ({
            ...item2,
            file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
              item2?.file
            }`,
          })),
          siteProfiles: action.payload?.siteProfiles?.map(item2 => ({
            ...item2,
            languages: item2?.languages?.id && {
              ...item2?.languages,
              icon: item2?.languages?.icon?.file && {
                ...item2?.languages?.icon,
                file: `${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${
                  item2?.languages?.icon?.file
                }`,
              },
            },
          })),
        },
      };
    },
  },
};
